window.$ = window.jQuery = require('jquery');

(function($){
    window.onbeforeunload = function(e){
        window.name += ' [' + $(window).scrollTop().toString() + '[' + $(window).scrollLeft().toString();
    };
    $.maintainscroll = function() {
        if(window.name.indexOf('[') > 0)
        {
            var parts = window.name.split('[');
            window.name = $.trim(parts[0]);
            window.scrollTo(parseInt(parts[parts.length - 1]), parseInt(parts[parts.length - 2]));
        }
    };
    $.maintainscroll();
})(jQuery);

window.onload = function () {
    $(document).ready(function () {

        $('img').lazyload({
            effect: 'fadeIn',
            threshold: 500,
        });


        $('.main-top-slider').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            cssEase: 'linear'
        });


        $('.pop-slider-home').slick({
            dots: true,
            prevArrow: false,
            nextArrow: false,
            infinite: true,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 3,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1124,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        $('.shop-product-slider').slick({
            dots: true,
            prevArrow: false,
            nextArrow: false,
            infinite: true,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 3,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1124,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });


        $('#search_input').keyup(function () {
            let query = $(this).val();


            if (query != '' && query.length >= 4) {
                setTimeout(function () {

                    $.ajax({
                        url: "/fetch",
                        method: "POST",
                        data: {
                            query: query,
                        },

                        success: function (data) {

                            // console.log(data);
                            $('.search-autocomplete-result-cn').fadeIn();
                            $('.search-autocomplete-result-cn-aj').fadeIn();
                            $('.search-autocomplete-result-cn .search-autocomplete-result-cn-aj').html(data);

                            let $para = $('.search-autocomplete-result-cn-aj');


                            $para.find('.highlight').contents().unwrap();

                            let mysearchword = query.trim();

                            // if (mysearchword) {
                            //     let re = new RegExp('(' + mysearchword.trim().split(/\s+/).join('|') + ')', "gi");
                            //     $para.html(function (i, html) {
                            //         return html.replace(re, '<span class="highlight">$1</span>')
                            //     });
                            // }

                            if (mysearchword) {

                                $para.html(function () {
                                    return $(this).html().replace(new RegExp(mysearchword + "(?=[^>]*<)", "ig"), "<span class=\"highlight\">$&</span>");
                                });
                            }

                        }
                    });
                    $('.recent-searches').fadeOut();
                    $('.popular-searches').fadeOut();

                }, 1000);

                // console.log(query);

            }
            if (query == '' && query.length == 0) {
                $('.search-autocomplete-result-cn-aj').fadeOut();
                $('.recent-searches').fadeIn();
                $('.popular-searches').fadeIn();
            }
        });

        $('#search_input').on('click', function () {
            // let query = $(this).val();
            // let old_cookie = $.cookie('cookie_q');
            // if(query != '' || old_cookie != undefined){
            $('.search-autocomplete-result-cn').fadeIn();
            // }

        })

        $(document).on('click', '.search-autocomplete-result ul li', function () {
            $('#search_input').val($(this).text());
            $('.search-autocomplete-result-cn').fadeOut();
            // $('form#search_form').submit();
        });

        $(document).mouseup(function (e) {
            var container = $(".search-autocomplete-result-cn");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });

        $(document).on('click', '.get_search', function (e) {
            e.preventDefault();
            let query = [];

            let old_cookie = $.cookie('cookie_q');

            if (old_cookie != undefined) {
                query.push(old_cookie);
            }

            if (old_cookie != undefined) {
                if (!old_cookie.includes($('#search_input').val())) {
                    query.push($('#search_input').val());
                }
            } else {
                query.push($('#search_input').val());
            }

            // query.push($('#search_input').val());
            if (query.length != 0) {
                $.cookie('cookie_q', query, {expires: 1});
                $('form#search_form').submit();
            }
        });

        function recentSearches() {
            // console.log(111);
            let coo = $.cookie('cookie_q');

            if (coo != undefined) {
                let arr = [];

                arr = coo.split(',');

                let list = $('<ul/>');
                addLi(arr);
                $('.recent-searches').append(list);

                function addLi(result) {
                    $.each(result, function (index, value) {

                        $('<li />', {text: value}).appendTo(list);
                    });
                }

                $('.recent-searches').fadeIn();

            }
        }

        recentSearches();

        $('.remove-recent').on('click', function () {
            $.removeCookie('cookie_q');
            $('.recent-searches').fadeOut();
            $('.search-autocomplete-result-cn').fadeOut();

        });

        // get and show popular searches
        let popular_searches = 'popular_searches';
        $.ajax({
            url: "/popular-searches",
            method: "POST",
            data: {
                popular_searches: popular_searches,
            },
            success: function (data) {
                $('.search-autocomplete-result-cn .popular-searches').html(data);
            }
        });

        // Start sidebar filter
        let filters = {
            'cat_search': '',
            'sortby': '',
            'min_price': '',
            'max_price': '',
            'page': '',
            'cat_p': '',
            'shop': [],
            'brand': [],
        };


        let searchGetParam = new URLSearchParams(window.location.search);

        for (const [key, value] of Object.entries(filters)) {
            if (searchGetParam.has(key)) {
                if (searchGetParam.get(key) != '') {
                    let par = searchGetParam.get(key);

                    if (key == 'shop' || key == 'brand') {
                        filters[key] = par.split(',');
                    } else {
                        filters[key] = par;
                    }

                }
            }

        }

        function filterParams(filters) {

            for (const [key, value] of Object.entries(filters)) {

                if (value == '') {
                    delete filters[key];
                }
                // console.log(test);
            }

            let searchParams = new URLSearchParams(filters).toString();

            if ($.isEmptyObject(filters)) {
                window.history.replaceState(filters, '', location.pathname);
            } else {
                window.history.replaceState(filters, '', location.pathname + '?' + searchParams);
            }

        }

        $(document).on('change', '#filter-price-select', function () {
            filters.sortby = $(this).val();
            // console.log(filters);
            filterParams(filters);
            ajaxFilters();
        })

        $(document).on('click', '#m-cat-search-btn', function (e) {
            e.preventDefault();
            filters.cat_search = $('.m-cat-search').val();
            filterParams(filters);
            ajaxFilters();
        });

        // filter by min, max price
        $(document).on('click', '#m-price-filter', function () {
            // e.preventDefault();

            filters.min_price = $('#min-price').val();
            filters.max_price = $('#max-price').val();
            // console.log(filters);
            filterParams(filters);
            ajaxFilters();
        });

        // filter by Brand
        $(document).on('change', '.m-brand-ls-cn .form-check-input', function () {

            if (this.checked) {
                if (filters.brand == undefined) {
                    filters.brand = [];
                }
                filters.brand.push($(this).val());
            } else {
                filters.brand.splice($.inArray(this, filters.brand), 1);
            }
            // filters.brand = brandIds;
            filterParams(filters);
            ajaxFilters();

        });

        // filter by shop
        $(document).on('change', '.m-shop-ls-cn .form-check-input', function () {

            console.log(filters);
            if (this.checked) {
                if (filters.shop == undefined) {
                    filters.shop = [];
                }

                filters.shop.push($(this).val());
            } else {
                if (filters.shop != undefined) {
                    let arr = filters.shop;
                    arr.splice($.inArray(this, arr), 1);
                }

            }

            filterParams(filters);
            ajaxFilters();
            // console.log(filters);
        });


        $(document).on('click', '.aj-pagination a', function (event) {
            event.preventDefault();

            $('li').removeClass('active');
            $(this).parent('li').addClass('active');

            var url = $(this).attr('href');
            var page = $(this).attr('href').split('page=')[1];
            filters.page = page;

            filterParams(filters);
            ajaxFilters(page);
        });

        function ajaxFilters(page) {
            // todo add loading class
            let grid_cook = $.cookie('col-row');
            let cat_id = $('#cat_id').val();
            $.ajax({
                url: "/filter",
                method: "POST",
                data: {
                    filters,
                    cat_id,
                    page,
                    grid_cook,
                },
                beforeSend: function () {

                    $('#overlay').fadeIn(300);

                },
                success: function (data) {

                    $('.m-cat-products-cn').html(data);

                    $('.m-cat-products-cn').scrollTop();

                },
                complete: function () {
                    if (page !== 1) {
                        $('html, body').animate({
                            scrollTop: $('.f-select-cn').offset().top
                        }, 0);
                    }
                }

            }).done(function () {
                setTimeout(function () {
                    $("#overlay").fadeOut(300);
                }, 500);
            });

        }

        //Child Cat ajax pagination
        $(document).on('click', '.aj-pagination-cat a', function (event) {
            event.preventDefault();

            $('.aj-pagination-cat li').removeClass('active');
            $(this).parent('li').addClass('active');

            var url = $(this).attr('href');
            var cat_p = $(this).attr('href').split('cat=')[1];
            filters.cat_p = cat_p;

            filterParams(filters);
            ajaxSubCat(cat_p);
        });

        function ajaxSubCat(cat_p) {
            // let grid_cook = $.cookie('col-row');
            let cat_id = $('#cat_id').val();
            $.ajax({
                url: "/sub-cat",
                method: "POST",
                data: {
                    filters,
                    cat_id,
                    cat_p,
                },
                beforeSend: function () {

                    $('#overlay').fadeIn(300);

                },
                success: function (data) {

                    // $('.test-aj').html(data);
                    $('.m-cat-list-cn').html(data);
                }
            }).done(function () {
                setTimeout(function () {
                    $("#overlay").fadeOut(300);
                }, 500);
            });
        }

        //Change product list grid
        let col_row = $.cookie('col-row');
        if (col_row == '1') {
            $('.m-cat-r').addClass('cat-row');
            $('.change-col-m-row').removeClass('c-active');
            $('.change-col-m-col').addClass('c-active');
        }

        $(document).on('click', '.change-col-m-col', function (event) {
            $(this).addClass('c-active');
            $('.change-col-m-row').removeClass('c-active');
            $('.m-cat-r').addClass('cat-row');
            $.cookie('col-row', '1', {expires: 1});
        });

        $(document).on('click', '.change-col-m-row', function (event) {
            $(this).addClass('c-active');
            $('.m-cat-r').removeClass('cat-row');
            $('.change-col-m-col').removeClass('c-active');
            $.removeCookie('col-row');
        });

        // Show filters on mobile

        $(document).on('click', '.m-cat-mob-filter .btn', function () {
            $('.m-cat-sidebar').addClass('open');
        });
        $(document).on('click', '.m-cat-sidebar-close', function () {
            $('.m-cat-sidebar').removeClass('open');
        });

        // Comments form validation

        $('.rew-form-cn').validate({
            rules: {
                rating: {
                    required: true
                },
                name: {
                    required: true
                },
                comment: {
                    required: true,
                    minlength: 100
                }
            },
            messages: {
                rating: {
                    required: "Įveskite 1-5 žv."
                },
                name: {
                    required: "Įveskite savo vardą"
                },
                comment: {
                    required: "Įveskite atsiliepimą",
                    minlength: "Min. simbolių: 100"
                }
            },
            errorPlacement: function (error, element) {
                if (element.is(":radio")) {
                    error.appendTo(element.parents('.rate-cn'));
                } else { // This is the default behavior
                    error.insertAfter(element);
                }
            }
        });


    });
}

